import React from "react";
import "./Fullbox.css";
import kathmandu from "../../Assets/Images/8.jpg";
const FullBox = () => {
  return (
    <div className="fullbox-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="fullbox-data">
              <h1 className="fullbox-heading">
                All Nepal
                <span className="fullbox-highlight"> Tours</span>
              </h1>
              <p className="fullbox-description">
                Depending upon the type of package you want to enjoy. we
                organaise any type of package on any day you wish to travel.Feel
                free to contact us
              </p>
              <ol className="fullbox-list">
                <a href="/Kathmandu-City-Tour">
                  <li className="fullbox-item">Kathmandu City Tour</li>
                </a>
                <a href="/Chitwan-Pokhara">
                  <li className="fullbox-item">Chitwan & Pokhara</li>
                </a>
                <a href="/Lumbini-pilgrimage-Tour">
                  <li className="fullbox-item">Lumbini Pilgrimage Tour</li>
                </a>
                <a href="/All-Nepal-Tour">
                  <li className="fullbox-item">All Nepal Tour</li>
                </a>
                <a href="/Combo-City-Tour">
                  <li className="fullbox-item">Combo City Tour</li>
                </a>
                <a href="/Sunrise-Sunset-Tour">
                  <li className="fullbox-item">Sunrise Sunset Tour</li>
                </a>
                <a href="/Jungle-Safari">
                  <li className="fullbox-item">Jungle Safari</li>
                </a>
                <a href="/Cultural-Heritage-Sites">
                  <li className="fullbox-item">Cultural Heritage Sites</li>
                </a>
                <a href="/Fully-Sightseeing">
                  <li className="fullbox-item">Fully Sightseeing</li>
                </a>
              </ol>

              <button href="/about-us" className="btn btn-primary text-light">
                Read More
              </button>
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="fullbox-img">
              <img
                src={kathmandu}
                alt="fullbox-product-list"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullBox;
