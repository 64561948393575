import React from "react";
import "./whatsApp.css";
const WhatsApp = (props) => {
  return (
    <div className="whatsapp-wrapper">
      <a
        aria-label="Chat on WhatsApp"
        href={props.wapplink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="whatsapp"></div>
      </a>
    </div>
  );
};

export default WhatsApp;
