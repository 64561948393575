import React from "react";
import "./InfoBox.css";
import passportimage from "../../Assets/Images/passport.png";
import Ticket from "../../Assets/Images/ticket.png";
import Guide from "../../Assets/Images/guide2.png";
import Hotel from "../../Assets/Images/hotel.png";
import Saftey from "../../Assets/Images/saftey.svg";
import certified from "../../Assets/Images/certified.png";

const InfoBox = () => {
  return (
    <section className="info-box">
      <div className="container-fluid">
        <div className="row">
          <div className="col center">
            <h1>Arranging Best Deals From 2020</h1>
            <hr />
          </div>
          <div className="services">
            <ul className="row">
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon passport">
                    <img src={passportimage} alt="Visa and Documentation" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/nepal-tourist-visa-information">
                        Visa and Documentation
                      </a>
                    </h4>
                    <p>
                      Easily obtain your Nepal Tourist Visa upon arrival at TIA,
                      Kathmandu airport. Find out more about the required
                      documents and procedures before you travel.
                    </p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon">
                    <img src={Ticket} alt="Ticketing and Transportations" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/contact-us">
                        Ticketing and Transportations
                      </a>
                    </h4>
                    <p>
                      Secure the most affordable airfare or choose from a wide
                      range of vehicles according to your preferences. Contact
                      us with your inquiries via phone or email.
                    </p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon">
                    <img src={Guide} alt="Guides Services" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/contact-us">
                        Guides and Porters
                      </a>
                    </h4>
                    <p>
                      Enjoy the expertise of professional guides with excellent
                      language skills to enhance your travel experience. Contact
                      us with your inquiries via phone or email.
                    </p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon">
                    <img src={Hotel} alt="Best Price" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/best-sellers">
                        Hotels and Restaurants
                      </a>
                    </h4>
                    <p>
                      We will help you find out the best quality food and
                      awesome accommodation service without compromising
                      quality. Don't miss out these valuable opportunities.
                    </p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon">
                    <img src={certified} alt="Expert Service" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/responsible-tourism">
                        Expertise And Professionals
                      </a>
                    </h4>
                    <p>
                      Our team of expert tour and trek organizers is committed
                      to creating the best packages and unforgettable memories
                      with the professional guides throughout your journey.
                    </p>
                  </div>
                </div>
              </li>
              <li className="col-lg-4 col-md-12">
                <div className="item">
                  <div className="intro-icon">
                    <img src={Saftey} alt="Safe Travel" />
                  </div>
                  <div className="intro-text">
                    <h4>
                      <a href="https://www.Arrangersnepal.com/responsible-tourism">
                        Safety and Security
                      </a>
                    </h4>
                    <p>
                      The safety of Mother Nature is the safety of all humans.
                      We encourage our staff and travelers to actively
                      participate in taking care of our environment during tours
                      and treks.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <hr />
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoBox;
