import React from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineTravelExplore } from "react-icons/md";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "./navbar.css";
import TopNav from "../TopNav/TopNav";

const NavBar = () => {
  return (
    <section className="navBarSection">
      <header>
        <TopNav />

        <Navbar
          expand="md"
          collapseOnSelect
          className="custom-navbar sticky-top"
        >
          <Navbar.Brand className="logo" as={NavLink} to="/" exact>
            <span>
              <MdOutlineTravelExplore className="logoicon" />
              <span className="logotext">Travel</span>
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto navLists">
              <Nav.Link as={NavLink} to="/" exact>
                Home
              </Nav.Link>
              <NavDropdown title="About" id="about-dropdown">
                <NavDropdown.Item as={NavLink} to="/about-us">
                  About Us
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/our-team">
                  Our Team
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/disclaimers">
                  Disclaimers
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/terms-condition">
                  Terms & Conditions
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/privacy-policy">
                  Privacy Policy
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Packages" id="packages-dropdown">
                <NavDropdown.Item as={NavLink} to="/packages1">
                  Packages1
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/packages2">
                  Packages2
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/packages3">
                  Packages3
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Products" id="products-dropdown">
                <NavDropdown.Item as={NavLink} to="/product1">
                  Product1
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/product2">
                  Product2
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/product3">
                  Product3
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Services" id="services-dropdown">
                <NavDropdown.Item as={NavLink} to="/service1">
                  Service1
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/service2">
                  Service2
                </NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/service3">
                  Service3
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/ContactUs">
                Contact Us
              </Nav.Link>
              <Nav.Link as={NavLink} to="/Extra">
                Extra
              </Nav.Link>
            </Nav>
            <Form className="my-2 my-lg-0">
              <div className="Form">
                <FormControl
                  className="NavInput"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <Button className="navBtn">Search</Button>
              </div>
            </Form>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </section>
  );
};

export default NavBar;
