import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
import "./socialicon.css";
const socialIcons = [
  {
    icon: FaFacebook,
    color: "#4267B2",
    name: "Facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: FaTwitter,
    color: "#1DA1F2",
    name: "Twitter",
    link: "https://www.twitter.com/",
  },
  {
    icon: FaInstagram,
    color: "#C13584",
    name: "Instagram",
    link: "https://www.instagram.com/",
  },
  {
    icon: FaYoutube,
    color: "#FF0000",
    name: "Youtube",
    link: "https://www.youtube.com/",
  },
  {
    icon: FaTiktok,
    color: "#000000",
    name: "Tiktok",
    link: "https://www.tiktok.com/",
  },
];

const SocialIcon = () => {
  return (
    <>
      <div className="social-icons">
        {socialIcons.map((icon) => {
          const IconComponent = icon.icon;
          return (
            <>
              <a
                key={icon.name}
                className="social-icon"
                style={{ color: icon.color }}
                title={icon.name}
                href={icon.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconComponent />
              </a>
            </>
          );
        })}
      </div>
    </>
  );
};

export default SocialIcon;
