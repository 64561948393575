import React from "react";

import "./scrollingtxt.css";

const Scrollingtext = (props) => (
  <div className="scrolling-text-container">
    <a href="/contact-us" target="_blank">
      <span className="scrolling-text">{props.scrolltext}</span>
    </a>
  </div>
);

export default Scrollingtext;
