import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import { Review } from "../../Assets/Data/Data";

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % Review.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonial-container">
      {Review.map((testimonial, index) => (
        <div
          className={`testimonial-card ${
            index === activeIndex ? "active" : ""
          }`}
          key={index}
        >
          <div className="testimonial-content">
            <div className="testimonial-rating">
              {renderStars(testimonial.rating)}
            </div>
            <div className="testimonial-info">
              <img
                src={testimonial.profileimg}
                alt="reviewer customer"
                className="testimonial-img"
              />
              <h3 className="testimonial-name">{testimonial.clientname}</h3>
            </div>
            <p className="testimonial-description">
              {testimonial.description}
              <br />
              <strong style={{ display: "block", margin: "0 auto" }}>
                -{testimonial.clientname}
              </strong>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <span key={i} className="star filled">
        &#9733;
      </span>
    );
  }
  if (hasHalfStar) {
    stars.push(
      <span key="half" className="star half-filled">
        &#9733;
      </span>
    );
  }
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <span key={`empty-${i}`} className="star">
        &#9734;
      </span>
    );
  }

  return stars;
};

export default Testimonials;
