import React from "react";
import "./front.css";
import image from "../../Assets/Images/1.jpg";
import { GrLocation } from "react-icons/gr";

const Front = () => {
  return (
    <>
      <section className="front">
        <div className="overlay"> </div>
        <img
          className="image"
          alt="Travel photos by us"
          src={image}
          muted
          autoPlay
          loop
          type="image/jpg"
        ></img>

        <div className="frontContent">
          <div className="textDiv">
            <h1 className="titletext">
              Book Your <span class="span">Holiday</span> with Us.
            </h1>
          </div>
          <div className="cardDiv flex">
            <div className="destinationInput">
              <label htmlFor="city">Select your Destination</label>
              <div className="input flex">
                <input type="text" placeholder="Enter City Name" />
                <GrLocation className="icon" />
              </div>
            </div>
            <div className="dateInput">
              <label htmlFor="date">Select Your Date</label>
              <div className="input flex">
                <input type="date" />
              </div>
            </div>
            <div className="priceInput">
              <div className="label_total flex">
                <label htmlFor="price">Max Price</label>
                <h3 className="total"> $5000 </h3>
              </div>
              <div className="input flex range">
                <input type="range" max="5000" min="1000" />
              </div>
            </div>
            <div className="btn filterBtn center">
              <span>Look More</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Front;
