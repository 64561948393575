import React from "react";
import SimpleForm from "../Form/SimpleForm";
import GoogleMap from "../GoogleMap/GoogleMap";
import footer from "../../Assets/Images/footer.png";
import "./footer.css";
import ntb from "../../Assets/Images/ntb.svg";
import nma from "../../Assets/Images/nma.svg";
import nplgov from "../../Assets/Images/nplgov.png";
import taan from "../../Assets/Images/taan.svg";

import natta from "../../Assets/Images/natta.jpg";
import iata from "../../Assets/Images/iata.png";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footertop container-fluid">
          <div className="row">
            <div className="col-12 col-lg-2 footer-column address">
              <h3>Arrangers Nepal Trek & Expedition Pvt. Ltd.</h3>
              <ul>
                <li>
                  <span className="fa fa-map-marker"></span> Thamel, Kathmandu,
                  Nepal
                </li>
                <li>
                  <span className="fa fa-envelope"></span>
                  arrangersnepal@gmail.com
                </li>
                <li>
                  <span className="fa fa-phone"></span>+977 1 5902088
                </li>
                <li>
                  <span className="fa fa-mobile"></span>+977 9860080670
                </li>
                <li>
                  <span className="fa fa-mobile"></span>+977 9863438333
                </li>
                <li>
                  <span className="fa fa-facebook"></span>
                  facebook.com/arrangersnepal
                </li>
                <li>
                  <span className="fa fa-instagram"> </span>
                  instagram.com/arrangersnepal
                </li>
                <li>
                  <span className="fa fa-linkedin"></span>
                  linkedin.com/arrangersnepal
                </li>
                <li>
                  <span className="fa fa-youtube"></span>
                  youtube.com/arrangersnepal
                </li>
              </ul>
            </div>

            <div className="col-12 col-lg-4 footer-column google">
              <h3>Find Us on Google</h3>
              <GoogleMap />
            </div>

            <div className="col-6 col-lg-3 footer-column form">
              <SimpleForm />
            </div>

            <div className="col-5 col-lg-2 footer-column Fmenu">
              <ul>
                <li>Home</li>
                <li>About</li>
                <li>Services</li>
                <li>Contact</li>
                <li>Tours</li>
                <li>Treks</li>
                <li>Guides</li>
                <li>Tickets</li>
                <li>Hotels</li>
                <li>International</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footerbottom ">
          <div className="footerimg">
            <img src={footer} alt="bottom footer" />
          </div>

          <div className="copyright">
            <div className="row">
              <div className="col-4 copyrighttext">
                &copy; {new Date().getFullYear()} Arrangers Nepal. All rights
                reserved.
              </div>
              <div className="col-5 registered  ">
                <img src={ntb} alt="registered on this " />

                <img src={nma} alt="registered on this" />

                <img src={nplgov} alt="registered on this" />

                <img src={taan} alt="registered on this" />
                <img src={natta} alt="registered on this" />
                <img src={iata} alt="registered on this" />
              </div>
              <div className="col-3 designer">
                Designed By -
                <span>
                  <a href="/bpparajuli-page">BP Parajuli</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
