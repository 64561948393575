import React from "react";
import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./app.css";
import Front from "./Component/Front/Front";
import NavBar from "./Component/NavBar/NavBar";
import Home from "./Pages/Home";
import Footer from "./Component/Footer/Footer";
const App = () => {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/NavBar" element={<Front />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
