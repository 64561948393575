import image1 from "../Images/1.jpg";
import image2 from "../Images/2.jpg";
import image3 from "../Images/3.jpg";
import image4 from "../Images/4.jpg";
import image5 from "../Images/5.jpg";
import image6 from "../Images/6.jpg";
import image7 from "../Images/7.jpg";
import image8 from "../Images/8.jpg";
import image9 from "../Images/9.jpg";
import image10 from "../Images/10.jpg";
import image11 from "../Images/Trekking2.png";
import whatsappimage from "../Images/whatsapp.png";

import profile1 from "../Images/profile1.png";
import profile2 from "../Images/profile2.png";
import profile3 from "../Images/profile3.png";
import profile4 from "../Images/profile4.png";
import profile5 from "../Images/profile5.png";
export const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  whatsappimage,
];
export const products = [
  {
    id: 1,
    title: "Everest BaseCamp Trek",
    price: "$500 - $1200",
    description:
      "Embark on an awesome and breathtaking trek to Everest Base Camp, the most famous trekking destination in Nepal.",
    img: image1,
    link: "/everest-basecamp-trek",
  },

  {
    id: 2,
    title: "Annapurna Circuit Trek",
    price: "$800 - $1500",
    description:
      "Experience the incredible Annapurna Circuit Trek, exploring diverse landscapes, remote villages, and stunning mountain views.",
    img: image2,
    link: "/annapurna-circuit-trek",
  },
  {
    id: 3,
    title: "Pokhara City Tour",
    price: "$200 - $400",
    description:
      "Discover the natural beauty of Pokhara, visit the serene Phewa Lake, explore the stunning Davis Falls, and enjoy the panoramic views of the Himalayas.",
    img: image3,
    link: "/pokhara-city-tour",
  },
  {
    id: 4,
    title: "Chitwan National Park Safari",
    price: "$300 - $600",
    description:
      "Embark on an exciting safari adventure in Chitwan National Park, spot rare wildlife species, enjoy jungle walks, and experience traditional Tharu culture.",
    img: image4,
    link: "/chitwan-national-park-safari",
  },
  {
    id: 5,
    title: "Kathmandu Cultural Tour",
    price: "$150 - $300",
    description:
      "Explore the rich cultural heritage of Kathmandu, visit UNESCO World Heritage Sites, and immerse yourself in the vibrant local traditions.",
    img: image5,
    link: "/kathmandu-cultural-tour",
  },
  {
    id: 6,
    title: "Langtang Valley Trek",
    price: "$700 - $1200",
    description:
      "Trek through the beautiful Langtang Valley, witness spectacular mountain views, encounter local Tamang communities, and experience their warm hospitality.",
    img: image6,
    link: "/langtang-valley-trek",
  },
  {
    id: 7,
    title: "Mardi Himal Trek",
    price: "$600 - $1000",
    description:
      "Embark on a scenic trek to Mardi Himal, trek through pristine forests, witness panoramic mountain views, and experience the tranquility of the region.",
    img: image7,
    link: "/mardi-himal-trek",
  },
  {
    id: 8,
    title: "Bungee Jumping Adventure",
    price: "$100 - $200",
    description:
      "Get your adrenaline pumping with an exhilarating bungee jumping experience in Nepal, leap from towering heights, and feel the thrill like never before.",
    img: image8,
    link: "/bungee-jumping-adventure",
  },
  {
    id: 9,
    title: "Ghorepani Poon Hill Trek",
    price: "$500 - $900",
    description:
      "Trek to Ghorepani and witness the breathtaking sunrise from Poon Hill, hike through rhododendron forests, and experience the local Gurung culture.",
    img: image9,
    link: "/ghorepani-poon-hill-trek",
  },
  {
    id: 10,
    title: "Upper Mustang Exploration",
    price: "$2000 - $3500",
    description:
      "Discover the hidden kingdom of Upper Mustang, explore ancient caves, visit monasteries, and experience the unique Tibetan-influenced culture.",
    img: image10,
    link: "/upper-mustang-exploration",
  },
  {
    id: 11,
    title: "Manaslu Circuit Trek",
    price: "$1200 - $1800",
    description:
      "Embark on a challenging trek around the majestic Manaslu Mountain, explore remote valleys, cross high passes, and experience the rich Tibetan-influenced culture.",
    img: image11,
    link: "/manaslu-circuit-trek",
  },
  {
    id: 12,
    title: "Rara Lake Trek",
    price: "$1000 - $1500",
    description:
      "Trek to the pristine Rara Lake, located in the remote Karnali region, and witness the breathtaking beauty of the turquoise lake surrounded by snow-capped mountains.",
    img: image1,
    link: "/rara-lake-trek",
  },
  {
    id: 13,
    title: "Everest Helicopter Tour",
    price: "$800 - $1200",
    description:
      "Experience the thrill of an Everest Helicopter Tour, soar above the mighty Himalayas, witness stunning aerial views of Everest, and land at the Everest Base Camp.",
    img: image2,
    link: "/everest-helicopter-tour",
  },
  {
    id: 14,
    title: "Gokyo Lakes Trek",
    price: "$900 - $1500",
    description:
      "Trek to the mesmerizing Gokyo Lakes, witness the turquoise glacial lakes, enjoy panoramic mountain views, and hike to Gokyo Ri for a stunning sunrise vista.",
    img: image3,
    link: "/gokyo-lakes-trek",
  },
  {
    id: 15,
    title: "Everest Mountain Flight",
    price: "$200 - $400",
    description:
      "Embark on an exhilarating Everest Mountain Flight, take a scenic flight around Everest, and witness the majestic peaks of the Himalayas from a unique perspective.",
    img: image4,
    link: "/everest-mountain-flight",
  },
  {
    id: 16,
    title: "Panchase Trek",
    price: "$400 - $800",
    description:
      "Embark on a short and scenic Panchase Trek, hike through lush forests, enjoy panoramic mountain views, and witness stunning sunrises and sunsets.",
    img: image5,
    link: "/panchase-trek",
  },
  {
    id: 17,
    title: "Golden Triangle Tour (India)",
    price: "$1200 - $1800",
    description:
      "Explore the iconic cities of Delhi, Agra, and Jaipur in India, visit historical sites like the Taj Mahal, and immerse in rich cultural heritage.",
    img: image6,
    link: "/golden-triangle-tour-india",
  },
  {
    id: 18,
    title: "Leh-Ladakh Motorcycle Expedition",
    price: "$1500 - $2500",
    description:
      "Embark on an adventurous motorcycle expedition to Leh-Ladakh, ride through breathtaking landscapes, and experience the unique culture of the region.",
    img: image7,
    link: "/leh-ladakh-motorcycle-expedition",
  },

  {
    id: 19,
    title: "Bhutan Cultural Tour",
    price: "$2500 - $4000",
    description:
      "Explore the magical kingdom of Bhutan, visit ancient monasteries, witness traditional festivals, and experience the authentic Bhutanese culture.",
    img: image8,
    link: "/bhutan-cultural-tour",
  },
  {
    id: 20,
    title: "Mount Kailash Mansarovar Yatra",
    price: "$3000 - $5000",
    description:
      "Embark on a sacred pilgrimage to Mount Kailash and Mansarovar Lake, experience the spiritual significance, and immerse in divine surroundings.",
    img: image9,
    link: "/kailash-mansarovar-yatra",
  },
];
export const AboutText = {
  AboutUs: `Greetings from Arrangers Nepal Trek & Expedition We are incredibly passionate about 
    crafting remarkable travel encounters that will fill your life with
     priceless memories. We have happily served explorers like you for 
     many years with a great love of discovery and a dedication to excellence.
      As you tour the most compelling locations on earth, let us organize the 
      best travel itinerary ever for you, guided by our knowledge and enthusiasm
       for adventure travel. We appreciate you thinking of us for your travel 
       requirements. We are eager to go out on an extraordinary journey with you 
       and assist you in making lifelong memories.`,
  AboutUs2: `Arrangers Nepal Pvt.Ltd. is a renowned travel agency located in Kathmandu. Our primary
         goal is to organize the best deals and offer top-notch packages to travelers. 
         We specialize in a wide range of tour and trekking programs, operating both within
          Nepal as an inbound destination and internationally as an outbound destination. 
          We invite you to embark on a journey of discovery, exploring the wonders of the 
          world and conquering the most breathtaking and awe-inspiring destinations, from 
          the lowest to the highest landmarks on Earth. Escape from the ordinary and let 
          your adventurous spirit soar.`,
};

export const Review = [
  {
    rating: 3.5,
    profileimg: profile1,
    clientname: "Alex Mathon",
    description: `With you guys, the trip was enjoyable. I'm grateful to Bijay for organizing the best trek.I had several truly memorable days in Nepal's Everest region. I used to daydream of visiting this mountain, but now I have captured it on video. My guide planned everything and was a big assistance to me. He prevented me from becoming bored. I am eager to travel there once more with this firm. I like to give a strong endorsement.
    We could see that it was really professional and reasonably priced.`,
  },
  {
    rating: 4.0,
    profileimg: profile2,
    clientname: "Emily Johnson",
    description: `I had an amazing experience with this travel agency. The tour guide was very knowledgeable and friendly. The itinerary was well-planned, and I got to explore the beautiful sights and immerse myself in the local culture. The accommodations and transportation arrangements were also top-notch. I highly recommend their services to anyone looking for a memorable travel experience.`,
  },
  {
    rating: 3.6,
    profileimg: profile4,
    clientname: "John Anderson",
    description: `I recently went on a trekking adventure with this company, and it exceeded my expectations. The guides were experienced and ensured our safety throughout the journey. The views were breathtaking, and the whole experience was unforgettable. The team took care of all the logistics, allowing me to fully enjoy the trek. I would definitely choose them again for my future travel plans.`,
  },
  {
    rating: 4.5,
    profileimg: profile3,
    clientname: "Sophia Smith",
    description: `I had a wonderful time exploring Nepal with this travel agency. The staff was friendly and professional, and they customized the itinerary to suit my preferences. The accommodations were comfortable, and the local guides were knowledgeable and enthusiastic. I felt well taken care of throughout the trip. I highly recommend their services for anyone planning a trip to Nepal.`,
  },
  {
    rating: 4.2,
    profileimg: profile5,
    clientname: "Michael Brown",
    description: `My experience with this travel agency was absolutely fantastic. From the moment I contacted them, they were responsive and attentive to my needs. The entire trip was well-organized, and the guides went above and beyond to make sure we had an incredible time. The sights we visited were stunning, and the activities were thrilling. I can't thank them enough for creating such a memorable journey for me.`,
  },
];
