import React from "react";
const GoogleMap = () => {
  return (
    <div className="col-md-4">
      <iframe
        title="Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126758.26235259556!2d-118.24368090971906!3d34.05223595406238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c6b954fb9a95%3A0x9a3c1f4df2a5f8f!2sLos%20Angeles%2C%20CA%2C%20USA!5e0!3m2!1sen!2suk!4v1620451011014!5m2!1sen!2suk"
        width="350px"
        height="280px"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
