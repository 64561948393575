import React from "react";
import SocialIcon from "../SocialIcon/SocialIcon";
import Scrollingtext from "../scrollingText/Scrollingtxt";
import "./topnav.css";

const TopNav = () => {
  return (
    <div className="top-nav">
      <div className="scroll-text-wrapper">
        <Scrollingtext
          scrolltext={
            "24*7 Service Contact via WhatsApp, Viber, or Direct Call +9779800000000 / 98123456789"
          }
        />
      </div>
      <div className="social-wrapper">
        <SocialIcon />
      </div>
    </div>
  );
};

export default TopNav;
