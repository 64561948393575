import React from "react";
import { images } from "../../Assets/Data/Data";
import "./BestTrek.css";

const BestTrek = () => {
  return (
    <div className="container">
      <hr className="hr" />
      <div className="row">
        <div className="col-md-12">
          <h2 className="widgettitle center">
            Best <span className="span">Trekking</span> Packages
          </h2>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="section section1">
            <ul>
              <li>
                <strong>Everest Base Camp</strong>
                <p>
                  Embark on our 7 Night 8 Days Everest Base Camp trek, renowned
                  for its awe-inspiring beauty in the Himalayas.
                </p>
                <a
                  href="/Everest-Base-Camp/"
                  className="btn btn-primary btn-sm"
                >
                  Learn More &gt;&gt;
                </a>
              </li>
            </ul>
          </div>
          <div className="section section2">
            <ul>
              <li>
                <strong>Langtang Valley Trek</strong>
                <p>
                  Immerse yourself in the untouched beauty of the Langtang
                  Valley with our 8 Night 9 Days Langtang Valley Trek, offering
                  panoramic mountain views, dense forests, and picturesque
                  villages.
                </p>
                <a
                  href="/Langtang-Valley-Trek/"
                  className="btn btn-primary btn-sm"
                >
                  Learn More &gt;&gt;
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-4 d-none  d-lg-flex justify-content-center align-items-center">
          <figure className="service-img">
            <img
              src={images[10]}
              alt="solo traveller on a trek"
              className="img-responsive"
            />
          </figure>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="section section3">
            <ul>
              <li>
                <strong>Annapurna Circuit Trek</strong>
                <p>
                  Prepare for an extraordinary journey with our 12 Night 13 Days
                  Annapurna Circuit Trek, showcasing diverse landscapes and
                  stunning mountain views.
                </p>
                <a
                  href="/Annapurna-Circuit-Trek/"
                  className="btn btn-primary btn-sm"
                >
                  Learn More &gt;&gt;
                </a>
              </li>
            </ul>
          </div>
          <div className="section section4">
            <ul>
              <li>
                <strong>Mardi Himal Trek</strong>
                <p>
                  Experience the beauty of the Mardi Himal region with our 6
                  Night 7 Days Mardi Himal Trek, featuring breathtaking mountain
                  vistas and charming Gurung villages.
                </p>
                <a href="/Mardi-Himal-Trek/" className="btn btn-primary btn-sm">
                  Learn More &gt;&gt;
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestTrek;
