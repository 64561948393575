import React from "react";
import { products } from "../../Assets/Data/Data";
import "./SliderSection.css";

const SliderSection = () => {
  return (
    <section id="slider" className="slider">
      <div id="main-slide" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner" role="listbox">
          {products.map((offer, index) => (
            <div
              key={index}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <div className="slider-content text-center">
                <div className="col-md-12">
                  <a href={offer.link}>
                    <div className="blink">{offer.price}</div>
                    <div className="image-container">
                      <img src={offer.img} alt="another package" />
                      <div className="image-overlay">
                        <div className="overlay-content">
                          <h3>{offer.title}</h3>
                          <p>{offer.description}</p>
                          <button className="btn">Click Here</button>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <a
          className="carousel-control-prev"
          role="button"
          data-bs-slide="prev"
          href="#main-slide"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
        </a>
        <a
          className="carousel-control-next"
          role="button"
          data-bs-slide="next"
          href="#main-slide"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
        </a>
      </div>
    </section>
  );
};

export default SliderSection;
