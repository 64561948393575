import React from "react";
import { GiHiking, GiHelicopter, GiCityCar } from "react-icons/gi";
import { TbTrekking, TbPlaneInflight } from "react-icons/tb";
import { FaMotorcycle } from "react-icons/fa";
import WhatsApp from "../WhatsApp/WhatsApp";
import "./ActivityList.css";
import SocialIcon from "../SocialIcon/SocialIcon";

const ActivityList = () => {
  return (
    <div className="ActivityList">
      <div className="row">
        <div className="box">
          <a href="/trekking">
            <TbTrekking className="icon" />
            <span>Trekking</span>
          </a>
        </div>
        <div className="box">
          <a href="/city-tours">
            <GiCityCar className="icon" />
            <span>City Tours</span>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="box">
          <a href="/flights-booking">
            <TbPlaneInflight className="icon" />
            <span>Flights Booking</span>
          </a>
        </div>
        <div className="box">
          <a href="/heli-tours">
            <GiHelicopter className="icon" />
            <span>Heli tours</span>
          </a>
        </div>
      </div>

      <div className="row">
        <div className="box">
          <a href="/hiking">
            <GiHiking className="icon" />
            <span>Hiking</span>
          </a>
        </div>
        <div className="box">
          <a href="/bike-tours">
            <FaMotorcycle className="icon" />
            <span>Bike Tours</span>
          </a>
        </div>
      </div>
      <div className="column">
        <div>
          <SocialIcon />
        </div>
        <div>
          <WhatsApp wapplink={"https://wa.me/9860080670"} />
        </div>
      </div>
    </div>
  );
};

export default ActivityList;
