import React from "react";
import Front from "../Component/Front/Front";
import Greeting from "../Component/Greeting/Greeting";
import ActivityList from "../Component/ActivityList/ActivityList";
import PopularProducts from "../Component/Popular/Popular";
import BestTrek from "../Component/BestTrek/BestTrek";
import SliderSection from "../Component/Slider/SliderSection";
import InfoBox from "../Component/InfoBox/InfoBox";
import FullBox from "../Component/FullBox/FullBox";
import Testimonials from "../Component/Testimonials/Testimonials";
const Home = () => {
  return (
    <>
      <div>
        <Front />
      </div>
      <div className="home-row">
        <div className="home-box1">
          <Greeting />
        </div>
        <div className="home-box2">
          <ActivityList />
        </div>
      </div>
      <div>
        <PopularProducts />
      </div>
      <div>
        <BestTrek />
      </div>

      <div>
        <FullBox />
      </div>
      <div>
        <InfoBox />
      </div>
      <div>
        <SliderSection />
      </div>
      <div>
        <Testimonials />
      </div>
    </>
  );
};

export default Home;
