import React, { useState } from "react";
import "./Popular.css";
import { products } from "../../Assets/Data/Data";

const PopularProducts = () => {
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    if (page === "<" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (page === ">" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (typeof page === "number") {
      setCurrentPage(page);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  const renderProductCards = () =>
    currentProducts.map((product) => (
      <div className="col-md-3 col-12" key={product.id}>
        <div className="popular-card">
          <a href={product.link}>
            <div className="popular-img">
              <img
                src={product.img}
                alt={`Services we provide ${product.title}`}
              />
            </div>
            <div className="popular-detail">
              <h3 className="popular-title">{product.title}</h3>
              <h4 className="popular-price">{product.price}</h4>
              <p className="popular-description">{product.description}</p>
              <p>
                <button className="cardbtn btn">
                  <a href={product.link}>Know More &gt;&gt;</a>
                </button>
              </p>
            </div>
          </a>
        </div>
      </div>
    ));

  const totalPages = Math.ceil(products.length / itemsPerPage);
  const pageNumbers = Array.from(Array(totalPages), (_, index) => index + 1);

  return (
    <div className="popular-Packages">
      <div className="container-fluid">
        <h1 className="text-center popular-products-heading">
          Our <span className="text-primary">Popular</span> Packages.
        </h1>
        <div className="row">{renderProductCards()}</div>
        {totalPages > 1 && (
          <nav>
            <ul className="pagination justify-content-center">
              <button
                className="page-link"
                onClick={() => handlePageChange("<")}
              >
                &lt;
              </button>

              {pageNumbers.map((page) => (
                <li
                  className={`page-item ${
                    currentPage === page ? "active" : ""
                  }`}
                  key={page}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </li>
              ))}

              <button
                className="page-link"
                onClick={() => handlePageChange(">")}
              >
                &gt;
              </button>
            </ul>
          </nav>
        )}
      </div>
    </div>
  );
};

export default PopularProducts;
