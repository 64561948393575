import React from "react";
import "./greeting.css";
import { AboutText } from "../../Assets/Data/Data";
function Greeting() {
  const currHour = new Date().getHours();
  let greeting = "";
  let cssStyle = {};

  if (currHour <= 11.59) {
    greeting = "Good Morning";
    cssStyle = {
      color: "green",
      backgroundColor: "yellow",
      padding: "5px",
    };
  } else if (currHour <= 16) {
    greeting = "Good Afternoon";
    cssStyle = {
      color: "brown",
      backgroundColor: "orange",
      padding: "5px",
    };
  } else if (currHour <= 19) {
    greeting = "Good Evening";
    cssStyle = {
      color: "white",
      backgroundColor: "brown",
      padding: "5px",
    };
  } else {
    greeting = "Good Night";
    cssStyle = {
      color: "yellow",
      backgroundColor: "black",
      padding: "5px",
    };
  }
  return (
    <div className="greet center">
      <span style={cssStyle}>{greeting} Visitors</span>
      <p className="greeting">{AboutText.AboutUs}</p>
      Travel safely!
    </div>
  );
}

export default Greeting;
